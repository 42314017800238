<template>
  <div v-frag>
    <v-col cols='12' lg='5' md='5' sm='5' xl='5' xs='12'>
      <v-img :src='loginPageContent.background_image || image' height='100%'>
        <v-card
          class='text-center'
          elevation='0'
          height='100%'
          style='background-color: transparent'
          width='100%'
        >
          <v-sheet
            class='text-center'
            color='primary'
            elevation='0'
            height='100%'
            style='opacity: 0.8; position: absolute; z-index: 998'
            width='100%'
          ></v-sheet>
          <v-container v-if='loginPageContent.quote' class='pl-15 pr-15' fill-height>
            <v-row
              align='center'
              align-content='center'
              class='text-center'
              justify='center'
              style='z-index: 999'
            >
              <v-col class='mb-10' cols='8'>
                <v-img :src='quote' height='90' width='90'></v-img>
              </v-col>
              <v-col cols='8'>
                <div class='white--text text-start'>
                  {{ loginPageContent.quote }}
                </div>
              </v-col>
              <v-col class='mb-12' cols='8'>
                <div class='white--text text-start'>
                  <b>{{ loginPageContent.quote_author }}</b>
                </div>
              </v-col>
              <v-col cols='8'>
                <v-sheet class='low-angle' elevation='0' height='30' width='30'>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-img>
    </v-col>
  </div>
</template>

<script>
import image from '../../../assets/images/image1.jpeg'
import quote from '../../../assets/images/quote.png'
import { mapGetters } from 'vuex'

export default {
  name: 'LoginLeft',
  data: () => ({
    image: image,
    quote: quote
  }),
  computed: {
    ...mapGetters('cmsStore', ['loginPageContent'])
  }
}
</script>

<style scoped>
.low-angle {
  background-color: transparent;
  border-right: white solid 10px;
  border-bottom: white solid 10px;
  float: right;
}
</style>
